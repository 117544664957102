var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft_pool_detail art-pool-detail",class:{ 'pb-0': _vm.artpoolSimpleInfo.roundNo === 4 },style:({ backgroundImage: `url(${_vm.artpoolSimpleInfo.backgroundImageUrl})` }),attrs:{"id":"NftPool"}},[_c('article',{staticClass:"header"},[_c('section',{staticClass:"left"},[(_vm.artpoolSimpleInfo.bannerImagePcEnUrl && _vm.activeLanguage === 'en')?_c('img',{attrs:{"src":_vm.artpoolSimpleInfo.bannerImagePcEnUrl}}):(
          _vm.artpoolSimpleInfo.bannerImagePcUrl && _vm.activeLanguage === 'ko'
        )?_c('img',{attrs:{"src":_vm.artpoolSimpleInfo.bannerImagePcUrl}}):_c('img',{attrs:{"src":`/img/threespace/artpool/artpool_round${_vm.artpoolSimpleInfo.roundNo}_banner.png`}})]),_c('section',{staticClass:"right"},[_c('section',{staticClass:"title-wrap"},[_c('p',{staticClass:"round"},[_vm._v("#"+_vm._s(_vm.artpoolSimpleInfo.roundNo))]),_c('p',{staticClass:"title"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.title || _vm.artpoolSimpleInfo.titleEn)+" ")]:[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.titleEn || _vm.artpoolSimpleInfo.title)+" ")]],2),_c('p',{staticClass:"subtitle"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.subTitle || _vm.artpoolSimpleInfo.subTitleEn)+" ")]:[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.subTitleEn || _vm.artpoolSimpleInfo.subTitle)+" ")]],2)]),_c('div',{staticClass:"description"},[_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.formatPoolDates( _vm.artpoolSimpleInfo.poolStartDate, _vm.artpoolSimpleInfo.poolEndDate ))+" ")]),_c('p',{staticClass:"content"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.description || _vm.artpoolSimpleInfo.descriptionEn)+" ")]:[_vm._v(" "+_vm._s(_vm.artpoolSimpleInfo.descriptionEn || _vm.artpoolSimpleInfo.description)+" ")]],2)])])]),_c('article',{staticClass:"nft-description"},[_c('section',{staticClass:"item artists-item"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("nftpool.participatingArtists")))]),_c('div',{staticClass:"description artists"},[_c('p',{staticClass:"artist"},[_vm._v(_vm._s(_vm.$t("nftpool.artists")))]),(
            _vm.artpoolSimpleInfo.artists != undefined &&
            _vm.artpoolSimpleInfo.artists.length > 0
          )?[_c('div',_vm._l((_vm.artpoolSimpleInfo.artists),function(item,i){return _c('p',{key:'artist' + i,staticClass:"artist-item"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.artistName || item.artistNameEn)+" ")]:[_vm._v(" "+_vm._s(item.artistNameEn || item.artistName)+" ")]],2)}),0)]:[_c('p',{staticClass:"artist-item"},[_vm._v("참가 중인 작가가 없습니다.")])]],2)]),_c('section',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.stakeNft"))+" ")]),_c('div',{staticClass:"description"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.stakeNftContent"))+" ")]),_c('Button',{attrs:{"onClick":_vm.onClickGoStakePage,"buttonClass":{ round5: _vm.artpoolSimpleInfo.roundNo === 5 },"label":_vm.$t('nftpool.stakeNft')}})],1)]),_c('section',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.vote"))+" ")]),_c('div',{staticClass:"description"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.voteContent"))+" ")]),_c('Button',{attrs:{"onClick":_vm.onClickGoVotePage,"buttonClass":{ round5: _vm.artpoolSimpleInfo.roundNo === 5 },"label":_vm.$t('nftpool.vote')}})],1)])]),_c('article',{staticClass:"vote vote-result"},[_c('section',{staticClass:"title-wrap"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.finalVote"))+" ")]),_c('div',{staticClass:"subtitle"},[_c('p',[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[(_vm.$moment().isAfter(_vm.artpoolSimpleInfo.voteEndDate))?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.artpoolSimpleInfo.voteEndDate,"MM.DD"))+" "+_vm._s(_vm.$t("nftpool.finalVoteContentComplete"))+" ")]:[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.artpoolSimpleInfo.voteEndDate,"MM.DD"))+" "+_vm._s(_vm.$t("nftpool.finalVoteContent"))+" ")]]:[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.artpoolSimpleInfo.voteEndDate,"Do, MMM"))+" ")]],2)])]),_c('section',{staticClass:"winner"},[(
          _vm.topKip !== undefined &&
          _vm.$moment().isAfter(_vm.artpoolSimpleInfo.voteEndDate)
        )?_c('article',{staticClass:"result-item"},[_c('div',{staticClass:"img-wrap"},[(
              _vm.topKip.winnerCollection.imageUrl !== undefined &&
              _vm.topKip.winnerCollection.imageUrl !== null &&
              _vm.topKip.winnerCollection.imageUrl !== ''
            )?[_c('img',{attrs:{"src":_vm.topKip.winnerCollection.imageUrl}})]:_vm._e()],2),_c('section',{staticClass:"description"},[_c('p',{staticClass:"title"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.topKip.winnerCollection.title || _vm.topKip.winnerCollection.titleEn)+" ")]:[_vm._v(" "+_vm._s(_vm.topKip.winnerCollection.titleEn || _vm.topKip.winnerCollection.title)+" ")]],2),_c('div',{staticClass:"price"},[_c('p',[_vm._v("Price")]),_c('p',[_vm._v(_vm._s(_vm._f("currency")(Number(_vm.topKip.winningVote)))+" PACE")])]),_c('div',{staticClass:"nft-info"},[_c('div',[_c('p',[_vm._v("Artist")]),_c('p',[(
                    _vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko'
                  )?[_vm._v(" @"+_vm._s(_vm.topKip.winnerCollection.artist || _vm.topKip.winnerCollection.artistEn)+" ")]:[_vm._v(" @"+_vm._s(_vm.topKip.winnerCollection.artistEn || _vm.topKip.winnerCollection.artist)+" ")]],2)]),_c('div',[_c('p',[_vm._v("Num.")]),_c('p',[_vm._v(" #"+_vm._s(_vm.topKip.winnerCollection.tokenId)+" ("+_vm._s(_vm.topKip.winnerCollection.nftType)+") ")])])])]),_c('div',{staticClass:"vote-info"},[_c('p',[_vm._v("VOTE : ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(Number(_vm.topKip.userVoteAmount)))+" ")])])]):_c('article',{staticClass:"result-item"},[_c('img',{attrs:{"src":"/img/threespace/artpool/no-artwork.png"}}),_c('section',{staticClass:"description"},[(_vm.$moment().isAfter(_vm.artpoolSimpleInfo.voteEndDate))?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.no1stPlace"))+" ")]):_vm._e()])]),(
          _vm.topErc !== undefined &&
          _vm.$moment().isAfter(_vm.artpoolSimpleInfo.voteEndDate)
        )?_c('article',{staticClass:"result-item"},[_c('div',{staticClass:"img-wrap",class:{ round5: _vm.artpoolSimpleInfo.roundNo === 5 },style:({ color: _vm.artpoolSimpleInfo.secondaryColor })},[(
              _vm.topErc.winnerCollection.imageUrl !== undefined &&
              _vm.topErc.winnerCollection.imageUrl !== null &&
              _vm.topErc.winnerCollection.imageUrl !== ''
            )?[_c('img',{attrs:{"src":_vm.topErc.winnerCollection.imageUrl}})]:_vm._e()],2),_c('section',{staticClass:"description"},[_c('p',{staticClass:"title"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(_vm.topErc.winnerCollection.title || _vm.topErc.winnerCollection.titleEn)+" ")]:[_vm._v(" "+_vm._s(_vm.topErc.winnerCollection.titleEn || _vm.topErc.winnerCollection.title)+" ")]],2),_c('div',{staticClass:"price"},[_c('p',[_vm._v("Price")]),_c('p',[_vm._v(_vm._s(_vm._f("currency")(Number(_vm.topErc.winningVote)))+" PACE")])]),_c('div',{staticClass:"nft-info"},[_c('div',[_c('p',[_vm._v("Artist")]),_c('p',[(
                    _vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko'
                  )?[_vm._v(" @"+_vm._s(_vm.topErc.winnerCollection.artist || _vm.topErc.winnerCollection.artistEn)+" ")]:[_vm._v(" @"+_vm._s(_vm.topErc.winnerCollection.artistEn || _vm.topErc.winnerCollection.artist)+" ")]],2)]),_c('div',[_c('p',[_vm._v("Num.")]),_c('p',[_vm._v(" #"+_vm._s(_vm.topErc.winnerCollection.tokenId)+" ("+_vm._s(_vm.topErc.winnerCollection.nftType)+") ")])])])]),_c('div',{staticClass:"vote-info"},[_c('p',[_vm._v("VOTE : ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(Number(_vm.topErc.userVoteAmount)))+" ")])])]):_c('article',{staticClass:"result-item"},[_c('img',{attrs:{"src":"/img/threespace/artpool/no-artwork.png"}}),_c('section',{staticClass:"description"},[(_vm.$moment().isAfter(_vm.artpoolSimpleInfo.voteEndDate))?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("nftpool.no1stPlace"))+" ")]):_vm._e()])])])]),_c('article',{staticClass:"previous-exhibitions"},[_c('p',{staticClass:"title"},[_vm._v("PREVIOUS EXHIBITIONS")]),_c('section',{staticClass:"exhibition-list"},[_c('div',{staticClass:"slide-container"},[_c('div',{staticClass:"slide-wrapper"},[_vm._l((_vm.artpoolItems.content),function(item,i){return [_c('div',{key:'artpool-list' + i,staticClass:"slide",class:{ active: _vm.artpoolSimpleInfo.idx === item.idx },on:{"click":function($event){return _vm.onClickArtPoolListItem(item)}}},[_c('p',[_vm._v("#"+_vm._s(item.idx))]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":item.thumbnailImageUrl}})])])]}),_vm._l((_vm.artpoolItems.content),function(item,i){return [_c('div',{key:'artpool-list' + i,staticClass:"slide",class:{ active: _vm.artpoolSimpleInfo.idx === item.idx },on:{"click":function($event){return _vm.onClickArtPoolListItem(item)}}},[_c('p',[_vm._v("#"+_vm._s(item.idx))]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":item.thumbnailImageUrl}})])])]})],2)])]),(_vm.artpoolSimpleInfo.roundNo === 4)?_c('div',{staticClass:"nft_pool_bg_round4"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }